import images from './images';

const {man1, man2, girl1} = images;

export const testimonials = [
    {
      id: 1,
      name: "Simon Pascal",
      role: "Lead HR Regional Services Switzerland, UBS",
      company: "UBS",
      image: man1,
      text: `We came to know Alexandru as a motivated and interested intern who was keen to learn. His method of working was thorough and careful, and he was a valued contact as a result of his acquired specialist know-how.
      Alexandru always performed well, both quantitatively and qualitatively, and met with our complete satisfaction. He had good interpersonal skills, was self-assured, amiable and willing to help.
      Moreover, his conduct towards clients, superiors and colleagues alike was communicative and open.`

    },
    {
      id: 2,
      name: "Yves",
      role: "Senior Director Strategy Software Engineering, NBC",
      company: "NBC",
      image: man2,
      text: `I enjoyed working with Alexandru very much and will recommend his profile. It's really nice to work with him!`,
    },
    {
      id: 3,
      name: "Jessie",
      role: "Quality & OPX Director, Hitachi Energy",
      company: "Hitachi Energy",                                                                
      image: girl1,
      text: `His interactions with other employees were courteous and people appreciated his interventions. He was able to meet the objectives and we would surely recommend Alexandru.`,
      },
  ];
  