import React, { useState, useEffect } from 'react';
import { About, Footer, Header, Work, Experience, Skills, Testimonial } from './container';
import { Navbar } from './components';
import './App.scss';
import { images } from './constants';

const App = () => {
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  useEffect(() => {
    const preloadImages = async () => {
      const promises = Object.values(images).map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      try {
        await Promise.all(promises);
        setAssetsLoaded(true);
      } catch (error) {
        console.error('Some images failed to load', error);
      }
    };

    preloadImages();
  }, []);

  return (
    <div className="app">
      {assetsLoaded ? (
        <>
          <Navbar />
          <Header />
          <About />
          <Work />
          <Experience />
          <Skills />
          <Testimonial />
          <Footer />
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default App;
