//Skills
import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import css from '../assets/css.png';
import git from '../assets/git.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import rpa from '../assets/rpa.png';
import aws from '../assets/aws.png'
import cicd from '../assets/CICD.png'
import jenkins from '../assets/jenkins.png'
import aa from '../assets/automation.png'
import atlassian from '../assets/atlassian.png'
import docker from '../assets/docker.png'


//Header
import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import seng from '../assets/seng.png';
import worker from '../assets/worker.png';
import analysis from '../assets/analysis.png';

//About
import backend from '../assets/backend.png';
import project from '../assets/project.png';
import frontend from '../assets/frontend.png';
import polyhedric from '../assets/polyhedric.jpg';
import api from '../assets/api.jpg';
import se from '../assets/se.png';
import ml from '../assets/ml.jpg';

//work
import ai from '../assets/ai.jpg';
import cogen from '../assets/cogen.png';
import dl from '../assets/dl.png';
import laser from '../assets/laser.png';
import butterfly from '../assets/butterfly.png';
import ocean from '../assets/ocean.png';
import rankine from '../assets/rankine.png';
import solar from '../assets/solar.jpg';
import heater from '../assets/heater.png';
import geo from '../assets/geo.png';
import dash from '../assets/dash.png';

//Experience
import ubs from '../assets/UBS.png';
import nbc from '../assets/NBC.png';
import hitachi from '../assets/hitachi.png';
import esitech from '../assets/esitech.png';
import viseca from '../assets/viseca.png';

//Testimonials
import man1 from '../assets/man1.png'
import man2 from '../assets/man2.png'
import girl1 from '../assets/girl1.png'

const images = {
  backend,
  project,
  frontend,
  polyhedric,
  ml,
  se,
  api,

  ai, 
  cogen,
  dl,
  laser,
  butterfly,
  ocean,
  rankine,
  solar,
  heater,
  geo,
  dash,

  ubs,
  nbc,
  hitachi,
  esitech,
  viseca,

  profile,
  circle,
  seng,
  worker,
  analysis,

  email,
  mobile,
  css,
  git,
  html,
  javascript,
  node,
  python,
  react,
  rpa,
  aws,
  cicd,
  jenkins,
  aa,
  atlassian,
  docker,

  man1,
  man2,
  girl1,
};

export default images;
