import React, { useState, useEffect } from 'react';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({ username: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { username, email, message } = formData;

  const [isRateLimited, setIsRateLimited] = useState(false);
  const ONE_MINUTE = 60000; // 1 minute in milliseconds

  useEffect(() => {
    const lastSentTime = localStorage.getItem('lastSentTime');
    if (lastSentTime) {
      const timePassed = Date.now() - lastSentTime;
      if (timePassed < ONE_MINUTE) {
        setIsRateLimited(true);
        setTimeout(() => setIsRateLimited(false), ONE_MINUTE - timePassed);
      }
    }
  }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async () => {
    let newErrors = {};
  
    if (!formData.username) {
      newErrors.username = 'Name is required';
    }
  
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!formData.email.includes('@')) {
      newErrors.email = 'Invalid email address';
    }
  
    if (!formData.message) {
      newErrors.message = 'Message is required';
    }
  
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
  
    setLoading(true);
  
    try {
      const emailResponse = await window.Email.send({
        SecureToken: "e0b3a63f-7a35-4ed3-9405-f00321d497e3",
        To: 'alex@costrita.com',
        From: "costritaa@gmail.com",
        Subject: `New message from ${formData.username}`,
        Body: `Name: ${formData.username}<br>Email: ${formData.email}<br><br>Message:<br> ${formData.message}`
      });
  
      console.log("Email sent response:", emailResponse);
      
      setLoading(false);
      setIsFormSubmitted(true);
  
    } catch (error) {
      console.log('An error occurred:', error);
      setLoading(false);
    }
      // When message is successfully sent
      localStorage.setItem('lastSentTime', Date.now());
      setIsRateLimited(true);
      // Remove rate limit after 1 minute
      setTimeout(() => setIsRateLimited(false), ONE_MINUTE);
  };

  return (
    <>
      <h2 className="head-text">Intrigued? Let's turn possibility into <span>reality.</span></h2>

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="alex@costrita.com" className="p-text">alex@costrita.com</a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+(41) 78 267 08 48" className="p-text">+(41) 78 267 08 48</a>
        </div>
       </div>
        {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input className="p-text" type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
            {errors.username && <span className="error-message">{errors.username}</span>}
          </div>
          <div className="app__flex">
            <input className="p-text" type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>
          <div>
            <textarea className="p-text" placeholder="Your Message" name="message" value={message} onChange={handleChangeInput} />
            {errors.message && <span className="error-message">{errors.message}</span>}
          </div>
            <button 
              type="button" 
              className="p-text" 
              onClick={handleSubmit}
              disabled={isRateLimited}
            >
              {!loading ? (isRateLimited ? "Hold on 1 min for another message" : "Send Message") : "Sending..."}
            </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Message sent. Thanks!
          </h3>
        </div>
      )} 
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);
