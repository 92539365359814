import images from './images';

const { html, css, javascript, react, node, git, rpa, python, aws, cicd, jenkins, aa, atlassian, docker, ubs, nbc, hitachi, esitech, viseca } = images;

const technologies = [
  {
    name: "Python",
    icon: python,
    description: "5 years",
  },
  {
    name: "AWS",
    icon: aws,
    description: "2 years",
  },
  {
    name: "Atlassian",
    icon: atlassian,
    description: "2 years",
  },
  {
    name: "React JS",
    icon: react,
    description: "2 years",
  },
  {
    name: "Node JS",
    icon: node,
    description: "2 years",
  },
  {
    name: "JavaScript",
    icon: javascript,
    description: "2 years",
  },
  {
    name: "git",
    icon: git,
    description: "2 years",
  },
  {
    name: "HTML 5",
    icon: html,
    description: "2 years",
  },
  {
    name: "CSS 3",
    icon: css,
    description: "2 years",
  },
  {
    name: "RPA",
    icon: rpa,
    description: "2 years",
  },
  {
    name: "CI/CD",
    icon: cicd,
    description: "2 years",
  },
  {
    name: "Automation Anywhere",
    icon: aa,
    description: "1 year",
  },
  {
    name: "Jenkins",
    icon: jenkins,
    description: "1 year",
  },
  {
    name: "Docker",
    icon: docker,
    description: "1 year",
  },
];

const experiences = [
  {
    title: "Business Application Manager",
    company_name: "VISECA",
    websiteURL: "https://www.viseca.ch/en",
    icon: viseca,
    iconBg: "#FFFFFF",
    date: "2023 - Present",
    points: [
      "Foster partnerships with stakeholders including customers, project teams, legal and compliance entities to guide the development and implementation of solutions.",
      "Analyze business processes and document comprehensive requirements in agile and waterfall settings, translating complex features into user stories with clear criteria.",
      "Execute acceptance testing, provide ongoing operational support, and facilitate the creation of prototypes, driving continuous improvement and innovation.",
    ],
  },
  {
    title: "Software Automation Engineer intern",
    company_name: "UBS Switzerland AG",
    websiteURL: "https://www.ubs.com/ch/en.html",
    icon: ubs,
    iconBg: "#FFFFFF",
    date: "Jan 2023 - Jul 2023",
    points: [
      "Developed an Excel-based automation solution using VBA macros to standardize client invitations and extract essential data for dashboard analytics.",
      "Engineered a reminder letter generation system as part of a regulatory compliance process, leveraging automation techniques.",
      "Spearheaded the migration of existing Automation Anywhere RPA solutions to updated versions, ensuring seamless transition and functionality.",
      "Initiated and promoted the establishment of a standardized Python coding framework to unify development practices across the automation team.",
    ],
  },
  {
    title: "Project Manager/Team Lead",
    company_name: "ESITech Rouen",
    websiteURL:"https://esitech.univ-rouen.fr/",
    icon: esitech,
    iconBg: "#FFFFFF",
    date: "2022 - 2023",
    points: [

      "Leading and managing a team of 4 engineers.",
      "Implementation of a SCRUM framework for project development.",
      "Business plan and strategy development.",
      "Building and maintaining strong relationships with partners.",
      "Establishment and nurturing of client relationships.",
    ]
  },
  {
    title: "Quality Engineer intern",
    company_name: "Hitachi Energy",
    websiteURL:"https://www.hitachienergy.com/",
    icon: hitachi,
    iconBg: "#FFFFFF",
    date: "May 2022 - Aug 2022",
    points: [

      "Modernized a 50-year-old manufacturing infrastructure by integrating VBA and AWS-based software automation solutions, dramatically improving operational efficiency.",
      "Utilized ETQ for workflow reengineering, resulting in a 10% increase in production output through optimized process flows.",
      "Implemented software automation strategies using ETQ and AWS to reduce production time by 25%, directly contributing to an annual revenue boost of $2M.",
    ]
  },
  {
    title: "Software Engineer intern",
    company_name: "National Bank of Canada",
    websiteURL:"https://www.nbc.ca/",
    icon: nbc,
    iconBg: "#FFFFFF",
    date: "2021 - 2022",
    points: [
      "Engineered interfaces for a developer portal using JavaScript, Python, and NodeJS, focusing on JSON and XML data formats for seamless integration.",
      "Collaborated within a development team to establish a specialized component and tool utilizing Nexus and Jenkins, accelerating the bank's API development processes.",
      "Leveraged extensive experience in software design and development, implementing CI/CD pipelines to ensure high-quality, rapid deployments.",
    ],
  },
];

export { technologies, experiences };
export {images};
