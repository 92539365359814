import React, { useState, useEffect } from 'react';

import { technologies } from "../../constants/index";
import { AppWrap, MotionWrap } from '../../wrapper';
import BallCanvas from "./Ball";
import './Skills.scss';

const Skills = () => {
  const [activeDescription, setActiveDescription] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleDescription = (name) => {
    if (activeDescription === name) {
      setActiveDescription(null);
    } else {
      setActiveDescription(name);
    }
  };

  const technologiesToDisplay = isMobile ? technologies.slice(0,7) : technologies;

  return (
    <>
      <h2 className="head-text">Skills</h2>
      <div className="app__balls">
        {technologiesToDisplay.map((technology) => (
          <div 
            className={`ball-wrapper ${activeDescription === technology.name ? 'active' : ''}`} 
            key={technology.name} 
            onClick={() => toggleDescription(technology.name)}
          >
            <BallCanvas icon={technology.icon} />
            <div className="ball-info">
              <h4 className="ball-name">{technology.name}</h4>
              <p className="ball-description">{technology.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills',
  'app__whitebg',
);
