import React, { useState } from "react";
import { testimonials } from "../../constants/testimonial"; 
import "./Testimonial.scss";
import { AppWrap, MotionWrap } from '../../wrapper';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

const Testimonial = () => {
  const [current, setCurrent] = useState(0);

  const handleNext = () => {
    setCurrent((prev) => (prev + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrent((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  const { name, role, image, text } = testimonials[current];

  return (
    <div className="app__testimonial">
      <div className="app__testimonial-item">
        <img src={image} alt={name} />
        <div className="app__testimonial-content">
          <p className="p-text">{text}</p> 
          <div>
            <h4 className="bold-text">{name}</h4> 
            <h5 className="p-text">{role}</h5>
          </div>
        </div>
      </div>
      <div className="app__testimonial-btns">
        <div onClick={handlePrev}>
          <HiChevronLeft />
        </div>
        <div onClick={handleNext}>
          <HiChevronRight />
        </div>
      </div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(Testimonial, 'app__testimonial'),
  'testimonial',
  'app__primarybg',
);
