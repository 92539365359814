import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { AppWrap, MotionWrap } from '../../wrapper';

import "react-vertical-timeline-component/style.min.css";
import { experiences } from "../../constants/index";
import './Experience.scss';

const ExperienceCard = ({ experience }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{
        background: "#edf2f8",
        color: "#00000",
      }}
      contentArrowStyle={{ borderRight: "7px solid black" }}
      date={experience.date}
      iconStyle={{ background: experience.iconBg }}
      icon={
        <div className="experience__logo">
          <img
            src={experience.icon}
            alt={experience.company_name}
            className="experience__logo-img"
          />
        </div>
      }
    >
      <div>
        <h3 className="experience__title">{experience.title}</h3>
        <a href={experience.websiteURL} target="_blank" rel="noopener noreferrer">
        <p className="experience__company">{experience.company_name}</p>
        </a>
      </div>

      <ul className="experience__points">
        {experience.points.map((point, index) => (
          <li key={`experience-point-${index}`} className="experience__point-item">
            {point}
          </li>
        ))}
      </ul>
    </VerticalTimelineElement>
  );
};

const Experience = () => {
  return (
    <>
    <h2 className="head-text">Experiences</h2>
      <div className="app__experience">
        <VerticalTimeline className="vertical-timeline-custom-line">
          {experiences.map((experience, index) => (
            <ExperienceCard
              key={`experience-${index}`}
              experience={experience}
            />
          ))}
        </VerticalTimeline>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Experience, 'app__experience'),
  'experience',
  'app__whitebg',
);
