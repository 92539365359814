import images from './images';

const {  ai, cogen, dl, laser, butterfly, ocean, rankine, solar, heater, geo, dash} = images;

const works = [
    {
    title: "Virtual AI Assistant",
    description: "A friend to talk to.",
    tags: ["AI"],
    projectLink: "https://user-images.githubusercontent.com/81239843/264091587-4f42477f-3a98-463d-9f69-e8501e1472ad.jpg",
    codeLink: "https://github.com/AlexPhysics/AI",
    imgUrl: ai,
    },
    {
    title: "Machine/Deep Learning",
    description: "Self taught computer.",
    tags: ["ML/DL"],
    projectLink: "https://user-images.githubusercontent.com/81239843/264104260-c254cbd7-a20e-4a78-bbd5-e71b9b937827.png",
    codeLink: "https://github.com/AlexPhysics/Machine-Learning-Deep-Learning-Projects/tree/main",
    imgUrl: dl,
    },
    {
    title: "Spectrometry Research",
    description: "Laser applications.",
    tags: ["Research"],
    projectLink: "https://www.linkedin.com/in/costrita/overlay/1635528039786/single-media-viewer/?profileId=ACoAACyc2REBdmXR_Z9kexxyj2HbihCs0Yp93HM",
    codeLink: "https://www.linkedin.com/in/costrita/overlay/1635528039786/single-media-viewer/?profileId=ACoAACyc2REBdmXR_Z9kexxyj2HbihCs0Yp93HM",
    imgUrl: laser,
    },
    {
    title: "The Butterfly Effect",
    description: "Chaotic physical systems.",
    tags: ["Research"],
    projectLink: "https://www.linkedin.com/in/costrita/overlay/1635522613957/single-media-viewer/?profileId=ACoAACyc2REBdmXR_Z9kexxyj2HbihCs0Yp93HM",
    codeLink: "https://www.linkedin.com/in/costrita/overlay/1635522613957/single-media-viewer/?profileId=ACoAACyc2REBdmXR_Z9kexxyj2HbihCs0Yp93HM",
    imgUrl: butterfly,
    },
    {
    title: "Solar Power Plant",
    description: "Sunlight to electricity.",
    tags: ["Python"],
    projectLink: "https://user-images.githubusercontent.com/81239843/264086635-0026524d-6335-4ea2-beeb-2e15d85edeaf.jpg",
    codeLink: "https://github.com/AlexPhysics/PythonProjects/tree/main/Solar%20power%20plant",
    imgUrl: solar,
    },
    {
    title: "Geothermal Power Plant",
    description: "Earth's core as fuel.",
    tags: ["Python"],
    projectLink: "https://user-images.githubusercontent.com/81239843/263853969-14b1841e-b0ad-4c7f-98ba-bfbe6d6279bd.png",
    codeLink: "https://github.com/AlexPhysics/PythonProjects/tree/main/Geothermal%20power%20plant%20-%20binary%20cycle",
    imgUrl: geo,
    },
    {
    title: "Ocean Energy Conversion",
    description: "Cold into warm into energy.",
    tags: ["Python"],
    projectLink: "https://www.researchgate.net/figure/The-working-principle-for-closed-cycle-OTEC-A-working-fluid-such-as-ammonia-vaporizes-in_fig4_328741842",
    codeLink: "https://github.com/AlexPhysics/PythonProjects/tree/main/Cycle%20CLAUDE%20-%20Ocean%20thermal%20energy%20conversion%20(OTEC)",
    imgUrl: ocean,
    },
    {
    title: "Industrial Heater",
    description: `No "waste heat".`,
    tags: ["Python"],
    projectLink: "https://user-images.githubusercontent.com/81239843/264084701-d0bcc96b-0801-4ff7-92bf-f9cf27372a47.png",
    codeLink: "https://github.com/AlexPhysics/PythonProjects/tree/main/Rankine%20cycle%20-%20Industrial%20heater",
    imgUrl: heater,
    },
    {
    title: "Rankine Cycle",
    description: "Ideal thermodynamic cycle.",
    tags: ["Python"],
    projectLink: "https://user-images.githubusercontent.com/81239843/263850198-9ba69815-cee2-43cb-99d7-7d9552ae1391.png",
    codeLink: "https://github.com/AlexPhysics/PythonProjects/tree/main/Cycle%20Rankine%20%20Simple",
    imgUrl: rankine,
    },
    {
    title: "Cogeneration",
    description: "Heat and power.",
    tags: ["Python"],
    projectLink: "https://user-images.githubusercontent.com/81239843/264082131-47994256-27a7-4609-a8f3-9a40297f241b.png",
    codeLink: "https://github.com/AlexPhysics/PythonProjects/tree/main/Rankine%20Cycle%20Cogeneration%202",
    imgUrl: cogen,
    },
    {
      title: "Dash Plotly",
      description: "Real-time object detection.",
      tags: ["Python"],
      projectLink: "https://plotly.com/dash/",
      codeLink: "https://github.com/AlexPhysics/Dash_Plotly",
      imgUrl: dash,
      },
  ];
  
  export default works;
  