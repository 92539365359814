import images from './images';
const {project, polyhedric, api, se} = images;

export const aboutProfiles = [
  {
    title: 'Software Engineer',
    description: `I have 5 years of Python development experience, specializing in RPA solutions, backend processes, and machine learning.`,
    imgUrl: se,
  },
  {
    title: 'Requirements Engineer',
    description: `In my current role I conduct requirement analysis, and drive innovation. I optimize projects and applications for success.`,
    imgUrl: api,
  },
  {
    title: 'Project Management',
    description: `Having successfully managed a team of 4 engineers, I've crafted business plans, fostered strong partnerships and client relationships.`,
    imgUrl: project,
  },
  {
    title: 'Polyhedric Profile',
    description: `My career spans various roles and domains, creating a versatile skill set starting from business analysis to software engineering to project management.`,
    imgUrl: polyhedric,
  },
];